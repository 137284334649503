<!-- Visados Facturas -->

<template>
  <div class="visados contenedor" style="justify-content:center">
    <!-- Cabecera -->
    <div class="cabecera">
      <base_Header
        v-if="Entorno.header.header"
        :Entorno="Entorno.header"
        @onEvent="event_Header">
      </base_Header>
    </div>

    <div class="contenedor conflex" style="background-color:white;">
      
      <!-- Columna Listado -->
      <div class="columna" style="width:70%">
          <div class="conflex">
            <v-select
              v-bind="$select"
              v-model="schema.ctrls.apd.value"
              :label="schema.ctrls.apd.label"            
              :items="itemsApds"
              item-value="id"
              item-text="name"
              return-object
              @change="filterApds">             
            </v-select>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.txt.value"
              :label="schema.ctrls.txt.label"
              append-icon="mdi-magnify"
              @keypress.13="filterApds(schema.ctrls.apd.value)"
              @click:append="filterApds(schema.ctrls.apd.value)">
            </v-text-field>
          </div>


          <v-sheet v-bind="$cfg.styles.marco">
            <!-- Grid -->
            <base_Fgrid
              v-model="selected"
              :padre="stName" 
              :Entorno="Entorno"          
              :items-per-page=100
              show-select
              single-select 
              fixed-header   
              dense>          

              <!-- Slot botonera Top -->
              <template v-slot:top_btns="{ }">    
                <div v-if="accionRow.id!= 0" style="padding:10px 0 0 10px;">
                  <btraextra
                    :btra_permisos="btra_permisos"
                    :btra_particular="Entorno.btra"
                    btra_modulo="standard"
                    @onEvent="event_btra_Top">              
                  </btraextra>        
                </div>
              </template>

              <!-- Slot columna Grid -->
              <template v-slot:cta_name="{ Sitem }">
                {{ Sitem.cta_name }}
                <!-- <div class="light-blue--text text--darken-3"> {{ Sitem.fhhr|fechahr }}</div>  -->
              </template>

            </base_Fgrid>
          </v-sheet>
       


      </div>

      <div class="columna" style="width:30%;padding-left:10px">
        <div class="cab">EXPEDIENTE</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <div class="conflex">
            <vllabel
              style="flex: 1 1 50%"
              label="Código"
              :txt="accionRow.apd_cod">
            </vllabel>

            <vllabel
              style="flex: 1 1 50%"
              label="Coordinador Servicios"
              :txt="accionRow.apd_zona">
            </vllabel>
          </div>

          <vllabel
            style="flex: 1 1 100%"
            label="Nombre"
            :txt="accionRow.apd_name">
          </vllabel>

          <div class="conflex">
            <vllabel
              style="flex: 1 1 33%"
              :txt="accionRow.control">
            </vllabel>

            <vllabel
              style="flex: 1 1 33%"
              :txt="accionRow.ref">
            </vllabel>

            <vllabel
              style="flex: 1 1 33%"
              :txt="accionRow.fh|fecha">
            </vllabel>
          </div>

          <vllabel
            style="flex: 1 1 100%"
            :txt="accionRow.fallename">
          </vllabel>
        </v-sheet>

        <div class="cab">CONTACTO/S</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <div class="conflex">
            <vllabel
              style="flex: 1 1 50%"
              :txt="accionRow.con1">
            </vllabel>

            <vllabel
              style="flex: 1 1 50%"
              :txt="accionRow.con1t">
            </vllabel>
          </div>

          <div class="conflex">
            <vllabel
              style="flex: 1 1 50%"
              :txt="accionRow.con2">
            </vllabel>

            <vllabel
              style="flex: 1 1 50%"
              :txt="accionRow.con2t">
            </vllabel>
          </div>
        </v-sheet>

        <div class="cab">FACTURA</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <div class="conflex">
            <vllabel
              style="flex: 1 1 50%"
              :txt="accionRow.fra_doc">
            </vllabel>

            <vllabel
              style="flex: 1 1 50%"
              :txt="accionRow.fra_fh|fecha">
            </vllabel>
          </div>

          <div class="conflex">
            <vllabel
              style="flex: 1 1 100%"
              :txt="accionRow.cta_cont">
            </vllabel>
          </div>

          <div class="conflex">
            <vllabel
              style="flex: 1 1 100%"
              :txt="accionRow.cta_name">
            </vllabel>
          </div>

          <div class="conflex">
            <vllabel
              style="flex: 1 1 50%"
              :txt="accionRow.cta_tfn1">
            </vllabel>
          </div>
        </v-sheet>

        <div class="cab">VISADO</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <v-btn
            v-bind="$cfg.btra_cfg.standard"
            title="Visar Factura"
            @click="visar()">            
              <v-icon dark>{{ 'mdi-stamper' }}</v-icon>
          </v-btn>
          
          <v-textarea
            v-bind="$textarea"    
            v-model="schema.ctrls.observaciones.value"     
            :label="schema.ctrls.observaciones.label"
            no-resize>
          </v-textarea>
        </v-sheet>
      </div>

    </div>

    <!-- Ventana Expedientes -->
    <v-dialog
      v-model="modal"
      content-class="modal"
      persistent>
        <div class="centrado">
          <component                 
              :is="componente_dinamico"
              :padre="stName"
              :accion="accion"
              :accionRow="{ id: accionRow.exp_id }"
              :disparo="disparo"                
              @onEvent="event_Mto">
          </component>           
        </div>
    </v-dialog> 
  </div>
</template>



<script>
import { mixinFinder } from "@/mixins/mixinFinder.js";
import plugs from "@/common/general_plugs";
const base_Header = () => plugs.groute("base_Header.vue", "base");
const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
const exp_M = () => plugs.groute("exp_M.vue", "comp"); 

export default { 
  mixins: [mixinFinder], 
  components: { base_Header, base_Fgrid, btraextra, exp_M },
  props: {
    padre: { type: String, default: "" },
    id: { type: [Number, String], default: 0 },
    componenteTipo: { type: String, default: "FM" },
    disparoFinder: { type: Boolean, default: false },
    Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
 },


  data() {
    return {      
      api: "",
      stName: "stFvisados",
      accion: "0",
      accionRow: {},
      btra_permisos:{ extra:{'expedientes':1, 'informe':1, 'pdf':1 }},

      headers: {},
      modal:false,
      componente_dinamico:null,
      disparo:false,
      schema: {
        ctrls: {
          apd: { f:'apd', label:'APDS', value:'0' },
          txt: { f:'txt', label:'Busqueda', value:'' },
          observaciones: { f:'observaciones', label:'Observaciones', value:'' },
        }
      },
      itemsApds:[]    
    };
  },


  computed: {
    // obtengo / guardo fila seleccionada Grid
    selected: {
      get() {              
        if (!this.miSt.records.length) return [];
        return [this.accionRow];
      },

      set(item) {        
        console.log(item);
        if (!item.length) {
          this.accionRow= { id: 0 };
          return;
        } 
                  
        this.accionRow= JSON.parse(JSON.stringify(item[0]));     
        console.log(this.accionRow); 
      }
    },

  },


  methods: {
    ini_data() {
      this.Entorno.header.titulo = "Visado Facturas Proveedores";

      this.headers = {
        header: [
          { text: "Control", value: "control" },
          { text: "Referencia", value: "ref" },
          { text: "Servicio", value: "fh", filtro:'fecha' },
          { text: "Proveedor", value: "cta_name", slot:true },
          { text: "Factura", value: "fra_doc"}
        ]
      };

      // Inicializo cabeceras
      this.Entorno.grid.headers = this.set_headers();
      this.Entorno.btra= JSON.parse(JSON.stringify(this.$cfg.btra_tipo["F"]));
      this.Entorno.btra.footer.extra = [   
        { accion: "expedientes", btn: "btn_exp" },
        { accion: "pdf", btn: "btn_pdf", title:'Listado en Pdf' },
        { accion: "xls", btn: "btn_excel", title:'Listado en Excel' },
        { accion: "ver_pdf", icono: "mdi-receipt", title:'Ver Presupuesto/Factura' }
      ]             
    },


    // obtengo fras 
    async get_records() {
      let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
        accion:'visado',
        fnt:'get',
        zona:this.zona,
        apd:this.schema.ctrls.apd.value
      }};
    
      let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });    
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }

      // actualizo records en el store
      this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
      this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r });     

      // actualizo select APDS
      this.itemsApds= apiResult.r[1].slice();  
      
      // guardo primer elemento del array
      this.accionRow= this.miSt.records.length? this.miSt.records[0] : { id: 0 };      
    },


    //
    event_Header() {
      this.$router.push({ path:'/' });
    },


    // entra al cambiar de APD
    // filtrar el grid de fras con las del APD seleccionado
    filterApds(item) {      
      let txt= this.schema.ctrls.txt.value;
      let id= typeof item=== "object"? item.id : item;
      
      this.$store.commit(this.stName + '/data2State', { prop:'records', value:this.miSt.read_records.slice().filter(elem=> {
       
        if (txt!= '') {          
          return (id==0? elem.apd!= '' : elem.apd== id) && (
            elem.control.toLowerCase().includes(txt.toLowerCase()) || 
            elem.ref.toLowerCase().includes(txt.toLowerCase()) || 
            elem.cta_name.toLowerCase().includes(txt.toLowerCase()) ||           
            elem.fra_doc.toLowerCase().includes(txt.toLowerCase())
          )
        }

        return id==0? elem.apd!= '' : elem.apd== id;
      })})

      if (!this.miSt.records.length) return;
      this.accionRow= this.miSt.records[0];
    },


    // muestro ventana expedientes
    expedientes() {  
      console.log('accionROWWW: ', this.accionRow);  
      if (!this.accionRow) {
        this.$root.$alert.open('Debes seleccionar un expediente', 'info');
        return;
      }
      
      this.componente_dinamico= "exp_M";
      this.disparo= !this.disparo;
      this.modal= true;
    },


    // API para obtener el fichero (fra/doc) asociado al id recibido
    async ver_pdf() {
      let args = { tipo: 'fnc', accion: 'expediente', fn_args: { accion: "factura", id: this.accionRow.id, opc: 1 }};         
      let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }

      // abro documento
      window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");    
    },


    // visar factura
    async visar() {
      if (this.accionRow.id== 0) return;
      
      // monto argumentos de la llamada a la API
      let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
        accion:'visado',
        fnt:'set',
        id:this.accionRow.id,
        txt:this.schema.ctrls.observaciones.value        
      }};
      
      if (this.app== 2) {
        args.fn_args.apd= this.rol== 'apds'? this.schema.ctrls.apd.value.id : 0;
        args.fn_args.zona= this.rol== 'jz'? this.zona : 0;
      }

      // llamada API
      let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });      
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'success');
      }
      
      this.$root.$alert.open('Factura visada correctamente', 'success', 1800);

      // elimino del array del grid la fra visada
      this.$store.commit(this.stName + '/update_record', { opcion:'borrar', record:{}, id:this.accionRow.id });   

      // actualizo combo de APDS
      this.itemsApds= apiResult.r[0];

      // marco primer elemento del array
      if (!this.miSt.records.length) return;
      this.accionRow= this.miSt.records[0];
    },


    // muestro listado en pdf
    pdf() {
      this.show_listado(1);
    },


    // muestro listado en excel
    xls() {
      this.show_listado(2);
    },


    //
    async show_listado(tip) {
      if (this.accionRow.id== 0) return;
      
      // monto argumentos de la llamada a la API
      let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
        accion:'visado',
        fnt:'listado',
        apd:typeof this.schema.ctrls.apd.value=== 'object'? this.schema.ctrls.apd.value.id : this.schema.ctrls.apd.value,
        zona:this.zona,
        tip:tip
      }};
      console.log('args show_listado: ', args);
      let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });   
      console.log('apiResult show_listado: ', apiResult); 
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }

      // abro documento
      window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");
    }

  },
};
</script>
